import { RouterModule, Routes } from '@angular/router';

import { MainComponent } from './components/main.component';

const routes: Routes = [
    { path: '', component: MainComponent },
     {
        path: 'main',
        children: [

            { path: '', component: MainComponent }

        ]
    }
];

export const MainRoutes = RouterModule.forChild(routes);
