import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { Configuration } from '../app.constants';
import { ThingService } from './services/thing-data.service';
import { ResourceOrganizationService } from './services/resourceOrganization-data.service';
import { ResourceDirectoryService } from './services/resourceDirectory-data.service';
import { ExportDirectoryService } from './services/exportDirectory-data.service';
import { AccountService } from './services/account-data.service';
import { ContactService } from './services/contact-data.service';
import { DirectoryService } from './services/directory-data.service';
import { SearchService } from './services/search-data.service';

@NgModule({
    imports: [
        CommonModule
    ]
})

export class CoreModule {
    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                ThingService,
                ResourceDirectoryService,
                ResourceOrganizationService,
                ExportDirectoryService,
                AccountService,
                ContactService,
                DirectoryService,
                SearchService,
                Configuration
            ]
        };
    }
}
