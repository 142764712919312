import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MainComponent } from './components/main.component';
import { MainRoutes } from './main.routes';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        BrowserModule, BrowserAnimationsModule,
        MainRoutes
    ],

    declarations: [
        MainComponent
    ],

    exports: [
        MainComponent
    ]
})

export class MainModule { }
