import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router'; //ActivatedRoute,

import { ResourceOrganizationService } from './../../core/services/resourceOrganization-data.service';
import { ResourceOrganization } from './../../models/resourceOrganization';
//import { PagedResults } from './../../models/PagedResults';
//import { PaginationComponent } from './../../shared/components/pagination/pagination.component';

@Component({
    //selector: 'app-group-component',
    template: require('./resourceOrganization-list.component.html'),
    //styles:[require('./group-list.component.scss')]
})

export class ResourceOrganizationListComponent implements OnInit {
    headers: string[];
    public message: string;

    public resourceOrganization: ResourceOrganization = new ResourceOrganization();
    public resourceOrganizations: ResourceOrganization[] = [];
    public filteredGroups: ResourceOrganization[];
    totalRecords: number = 0;
    pageSize: number = 50;
    currentUser: 1;//User;
    //page = 1;
    //new paging variables
    //loading = false;
    //total = 0;
    //page = 1;
    //limit = 20;
    //new paging 
    sNode: string;
    filterByYear: number;
    public _listFilter: any;
    get listFilter(): any {
        return this._listFilter;
    }
    set listFilter(value: any) {
        this._listFilter = value;
        this.filteredGroups = this.listFilter ? this.performFilter(this.listFilter) : this.resourceOrganizations;
    }


    constructor(private dataService: ResourceOrganizationService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.message = 'Resource Organization Information ';
        this.filteredGroups = this.resourceOrganizations;
        this.listFilter = '';
    }

    ngOnInit() {
        //this.currentUser = this.authService.currentUser;

        //this.getGroupsPage(1);
        //this.getGroupsPageOrg(1);
        //this.GetGroups();
        this.route.queryParams
            //.filter(params => params.sNode)
            .subscribe(params => {
                console.log("orgId:" + params.orgId); // { order: "popular" }

                //this.order = params.sNode;
                //console.log(this.order); // popular
                if (params.orgId) {
                    this.sNode = params.sNode;
                    //this.GetResourceOrganizations(params.orgId);
                    this.GetResourceOrganization(params.orgId);
                }
                else {
                    this.GetAllResourceOrganizations();
                }
            }
            );
        //this.GetResourceOrganizations();

    }

    public performFilter(filterBy: any): ResourceOrganization[] {
        this.filterByYear = parseInt(filterBy, 10);
        //if (typeof this.filterByYear === "number" && !isNaN(this.filterByYear)) //this.filterByYear != NaN &&
        //{
        //    this.getRecipientsByYear(this.filterByYear);
        //    return this.recipients.filter((recipient: Recipient) => recipient.awardYears.intAwardYear == this.filterByYear);
        //    //return this.recipients.filter((recipient: Recipient) => recipient.idAwardYear == this.filterByYear);

        //}
        //public byYear: number; //= Int32.Parse(filterBy);
        //Console.log("{0} --> {1}", filterBy, number);
        if (typeof filterBy === "string") {
            filterBy = filterBy.toLocaleLowerCase();
            //this.GetGroupsByName(filterBy.toString());
            return this.resourceOrganizations.filter((resourceOrganization: ResourceOrganization) => resourceOrganization.name.toLocaleLowerCase().indexOf(filterBy.toString()) !== -1);
        }
        //return this.recipients.filter((recipient: Recipient) => recipient.idAwardYear == filterBy);

    }


    onClick(): void {
        //this.idclicked.emit('clicked');
        console.log('The ${this.Id} was clicked');
    }
    onIdClicked(id: number): void {
        this.message = 'List' + id;  //in html (idclicked)='onIdClicked($event)'
    }
    onBack(): void {
        //this.router.navigate(['/resourcedirectories']);
        this.sNode = JSON.parse(sessionStorage.getItem('dirName'));
        this.router.navigate(['/resourceDirectories'], { queryParams: { sNode: this.sNode } });
        //this.router.navigate([`${link.split('?')[0]}`], { queryParams: { id: 37, username: 'jimmy' } });
        //navigateurl: for complete url path, not used much
    }

    //private GetResourceOrganizations(orgId: string) {
    //    console.log("list:" + orgId);
    //    this.dataService            
    //        .GetResourceOrganizations(orgId)
    //        .subscribe(
    //            data => {
    //                this.resourceOrganizations = data;
    //                //this.resourceOrganization = data[0];
    //                this.filteredGroups = data;
    //                console.log(data);
    //                sessionStorage.setItem('resourceOrganization', JSON.stringify(this.resourceOrganizations));
    //            },
    //            error => console.log(error),
    //            () => console.log('Get all complete')
    //        );
    //}
    private GetAllResourceOrganizations() {
        this.dataService
            .GetAllResourceOrganizations()
            .subscribe(
                data => {
                    this.resourceOrganizations = data;
                    //this.resourceOrganization = data[0];
                    this.filteredGroups = data;
                    console.log("list" + data);
                    sessionStorage.setItem('resourceOrganization', JSON.stringify(this.resourceOrganizations));
                },
                error => console.log(error),
                () => console.log('Get all complete')
            );
    }
    private GetResourceOrganization(id: string) {
        this.dataService
            .GetResourceOrganization(id)
            .subscribe(
                data => {
                    this.resourceOrganization = data;
                    sessionStorage.setItem('resourceOrganization', JSON.stringify(this.resourceOrganization));
                },
                error => console.log(error),
                () => console.log('Get Resource Organization complete')
            );
    }

}   