/*
 *ngFor="let c of oneDimArray | sortBy:'asc'"
 *ngFor="let c of arrayOfObjects | sortBy:'asc':'propertyName'"
*/
//import { Pipe, PipeTransform } from '@angular/core';
//import { orderBy } from 'lodash';

//@Pipe({ name: 'sortBy' })
//export class SortByPipe implements PipeTransform {

//  transform(value: any[], order = '', column: string = ''): any[] {
//    if (!value || order === '' || !order) { return value; } // no array
//    if (value.length <= 1) { return value; } // array with only one item
//    if (!column || column === '') { 
//      if(order==='asc'){return value.sort()}
//      else{return value.sort().reverse();}
//    } // sort 1d array
//    //return orderBy(value, [column], [order]);
//      return orderBy(value, [column]);
//  }
//}

import { Injectable, Pipe, PipeTransform } from '@angular/core';

export type SortOrder = 'asc' | 'desc';

@Injectable()
@Pipe({
    name: 'sort',
})
export class SortPipe implements PipeTransform {
    transform(value: any[], sortOrder: SortOrder | string = 'asc', sortKey?: string): any {
        sortOrder = sortOrder && (sortOrder.toLowerCase() as any);

        if (!value || (sortOrder !== 'asc' && sortOrder !== 'desc')) return value;

        let numberArray = [];
        let stringArray = [];

        if (!sortKey) {
            numberArray = value.filter(item => typeof item === 'number').sort();
            stringArray = value.filter(item => typeof item === 'string').sort();
        } else {
            numberArray = value.filter(item => typeof item[sortKey] === 'number').sort((a, b) => a[sortKey] - b[sortKey]);
            stringArray = value
                .filter(item => typeof item[sortKey] === 'string')
                .sort((a, b) => {
                    if (a[sortKey] < b[sortKey]) return -1;
                    else if (a[sortKey] > b[sortKey]) return 1;
                    else return 0;
                });
        }
        const sorted = numberArray.concat(stringArray);
        return sortOrder === 'asc' ? sorted : sorted.reverse();
    }
}
