import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {  of } from 'rxjs';


//import { ExportDirectoryService } from './../../core/services/exportDirectory-data.service';
//import { Thing } from './../../models/thing';

@Component({
  selector: 'app-main-component',
  template: require('./main.component.html')
})
export class MainComponent implements OnInit {
    tradeAssistanceHeader: string;
    tradeAssistanceDesc: string;
    exportDirectories: any[] = [];
    sNode: string;

    public hasChildren = (item: any) => item.items && item.items.length > 0;
    public fetchChildren = (item: any) => of(item.items);
  
    constructor( 
        private route: ActivatedRoute,
        private router: Router) {
        this.tradeAssistanceHeader = 'Trade Resources Directory';
        this.tradeAssistanceDesc = "The International Trade Office's Trade Resources Directory connects Iowa exporters with a wide variety of resources to support international business.  Note:  Listings in this directory does not constitute endorsement";
  }

  ngOnInit(): void {
      this.route.queryParams
          .subscribe(params => {
              console.log("sNode:" + params.sNode); 
              if (params.sNode) {
                  this.sNode = params.sNode;
              }
          }
          );
  }
    navigateClick(dataItem: string): void {
        this.router.navigate(['/resourceDirectories', dataItem]);
    }
    
}
