import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {  of } from 'rxjs';


import { ExportDirectoryService } from './../../core/services/exportDirectory-data.service';
//import { Thing } from './../../models/thing';

@Component({
  selector: 'app-home-component',
  template: require('./home.component.html')
})
export class HomeComponent implements OnInit {
    tradeAssistanceHeader: string;
    tradeAssistanceDesc: string;
    tradeAssistanceDirectoryDesc: string;
    exportDirectories: any[] = [];
    sNode: string;
    dirType: number;
    searchCompany: string = "";
  //thing: Thing = new Thing();

    public expandedKeys: any[] = ['0', '1'];
    public selectedKeys: any[] = [''];

    public data: any[] = [
        {
            text: 'Educational Resources', items: [
                { text: 'Agriculture', value: "Agriculture" },
                { text: 'Business Resources for Iowa Companies'},
                { text: 'Country/Industry Information' },
                { text: 'Documentation Forms and Software' },
                { text: 'Educational' },
                { text: 'Export Compliance' },
                { text: 'Export Finance Options' },
                { text: 'Export Resources for Iowa Companies' },
                { text: 'Foreign Procurement (Multi-Lateral Development Banks)' },
                { text: 'Operational' },
                { text: 'Regional International Trade Organizations' },
                { text: 'Standards' },
                { text: 'Statistics' },
                { text: 'Tariffs & Taxes' },
                { text: 'Trade Agreements' },
                { text: 'Travel' },
                { text: 'U.S. Government' },
                { text: 'Wood Packaging' }
                
            ]
        }
    ];
  
    public hasChildren = (item: any) => item.items && item.items.length > 0;
    public fetchChildren = (item: any) => of(item.items);
        
    constructor(private exportDirectoryDataService: ExportDirectoryService, 
        private route: ActivatedRoute,
        private router: Router) {
        //this.tradeAssistanceHeader = 'Trade Resources Directory';
        //this.tradeAssistanceDesc = "The International Trade Office's Trade Resources Directory connects Iowa exporters with a wide variety of resources to support international business.  Note:  Listings in this directory does not constitute endorsement";
  }

    ngOnInit(): void {
        sessionStorage.setItem('searchType', JSON.stringify(false));
        this.route.params.subscribe(
            params => {
                this.dirType = params['id'];
                sessionStorage.setItem('dirType', JSON.stringify(this.dirType));
                console.log(this.dirType);
                if (this.dirType != 3) {
                    this.GetExportDirectories(this.dirType.toString());
                }
                
            }
        );
        if (this.dirType == 1) {
            this.tradeAssistanceHeader = "Iowa Directory of Exporters";
            this.tradeAssistanceDesc = "The International Trade Office markets the directory to buyers outside the United States who are interested in Iowa products.";
            this.tradeAssistanceDirectoryDesc = "The Iowa Directory of Exporters includes Iowa companies interested in or currently exporting their products. Companies representing the manufacturing and services sectors, agribusiness, biotechnology, grain, meat and foods are listed in the following categories.";
        } else if (this.dirType == 2) {
            this.tradeAssistanceHeader = "International Service Directory";
            this.tradeAssistanceDesc = "The International Trade Office offers the International Service Directory as a resource for exporters in locating Iowa service providers to support international sales.";
            this.tradeAssistanceDirectoryDesc = "";
        }
        else {
            this.tradeAssistanceHeader = 'Trade Resources Directory';
            this.tradeAssistanceDesc = "The International Trade Office's Trade Resources Directory connects Iowa exporters with a wide variety of resources to support international business.  Note:  Listings in this directory does not constitute endorsement";
        }
      
  }
    navigateClick(dataItem: string): void {
        this.router.navigate(['/resourceDirectories', dataItem]);
    }

    onBack(): void {
        this.router.navigate(['/main']);
        //navigateurl: for complete url path, not used much
    }
    searchDirectories(searchCompany: string) {
        sessionStorage.setItem('searchType', JSON.stringify(true));
        var sNode = searchCompany.replace(/\//g, "(FS)");
        if (sNode == undefined)
            this.router.navigate(['/home', sNode]);
        else {
            console.log("homecompdirtype" + this.dirType);
            if (this.dirType != 3) {
                console.log("homecompdirtyperesdir" + this.dirType);
                this.router.navigate(['/account'], { queryParams: { sNode: sNode } });
            }
            else {
                sNode = sNode + "|" + JSON.parse(sessionStorage.getItem('searchType'));
                this.router.navigate(['/resourceDirectories'], { queryParams: { sNode: sNode } });
            }
            //this.router.navigate(['/search/' , sNode]);
        }
    }
    //public handleSelection({ index }: any): void {
    public handleSelection(event: any){
        this.selectedKeys = event.dataItem.text;//[dataItem];
        console.log("sel:" + event.dataItem.text);
        console.log("dataitemid:" + event.dataItem.id);
        
        //console.log(servicesText);
        //var sNode = [dataItem.id +"|"+dataItem.text];
        if (this.dirType != 3) {
            var servicesText = event.dataItem.text.replace(/\//g, "(FS)");
            this.selectedKeys = [event.dataItem.id + "|" + servicesText + "|" + this.dirType];
            //console.log("select:" + [index]);
            //this.router.navigate(['/resourceDirectories?sNode=' + this.selectedKeys]);
            //this.router.navigate(['/resourceDirectories', { sNode: this.selectedKeys }]);
            this.router.navigate(['/account'], { queryParams: { sNode: this.selectedKeys } });
            //this.router.navigate(['/products'], { queryParams: { order: 'popular' } });
        }
        else {
            this.router.navigate(['/resourceDirectories'], { queryParams: { sNode: this.selectedKeys } });
        }
    }
    //public trackBy(index: number, item: any): any {
    //    //console.log(index);
    //    //console.log(item.text);
    //    return item.text;
    //}
    //onSearch(term: string) {
    //    this.router.navigate(['search', { term: term }]);
    //}
    private GetExportDirectories(sNode: string) {
        //sNode = "1";
        this.exportDirectoryDataService
            .GetExportDirectories(sNode)
            .subscribe(
                data => {
                    //this.exportDirectories = data;
                    this.data = data;
                    console.log("exportdirlist" + data);
                    //sessionStorage.setItem('resourceOrganization', JSON.stringify(this.resourceOrganizations));
                },
                error => console.log(error),
                () => console.log('Get all export directories list complete')
            );
    }
    //private GetExportDirectory(id: string) {
    //    this.exportDirectoryDataService
    //        .GetExportDirectory(id)
    //        .subscribe(
    //            data => {
    //                this.exportDirectory = data;
    //                //sessionStorage.setItem('resourceOrganization', JSON.stringify(this.resourceOrganization));
    //            },
    //            error => console.log(error),
    //            () => console.log('Get Export directory complete')
    //        );
    //}
}
