import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router'; //ActivatedRoute,
import { ContactService } from '../../core/services/contact-data.service';
import { DirectoryService } from '../../core/services/directory-data.service';
//import { AccountService } from '../../core/services/account-data.service';

//import { DetailService } from './../../core/services/detail-data.service';

//import { PhonePipe } from './../../pipes/phone-pipe';
//import { ResourceDirectory } from './../../models/resourceDirectory';
//import { PagedResults } from './../../models/PagedResults';
//import { PaginationComponent } from './../../shared/components/pagination/pagination.component';
import { GenderPipePipe } from '../../pipes/gender-pipe.pipe';
@Component({
    //selector: 'app-group-component',
    template: require('./detail.component.html'),
    //styles:[require('./group-list.component.scss')]
    providers: [
        //PhonePipe
        GenderPipePipe
    ]
})

export class DetailComponent implements OnInit {
    headers: string[];
    public tradeAssistanceHeader: string;

    public accountId: string;
    public account: any ; //= new any();
    public accounts: any[] = [];//ResourceDirectory[] = [];
    public contact: any;
    public contacts: any[] = [];
    public directory: any;
    public directories: any[] = [];
    public filteredGroups: any[];
    totalRecords: number = 0;
    pageSize: number = 50;
    public dirName = "";
    dirType: number;
    searchType: string;
    currentUser: 1;//User;
    
    filterByYear: number;
    public _listFilter: any;
    get listFilter(): any {
        return this._listFilter;
    }
    set listFilter(value: any) {
        this._listFilter = value;
        this.filteredGroups = this.listFilter ? this.performFilter(this.listFilter) : this.accounts;
    }
   
    constructor(//private dataService: AccountService,
        private contactService: ContactService, private directoryService: DirectoryService, 
        private route: ActivatedRoute,
        private router: Router
        ) 
    {
        //this.message = 'Resource Directories Information ';
        this.filteredGroups = this.accounts;
        this.listFilter = '';
        this.searchType = JSON.parse(sessionStorage.getItem('searchType'));
        console.log("consgructorsearchType:" + this.searchType);
        this.dirType = JSON.parse(sessionStorage.getItem('dirType'));
        
    }

    ngOnInit() {
        this.searchType = JSON.parse(sessionStorage.getItem('searchType'));
        console.log("searchType:" + this.searchType);
        this.dirType = JSON.parse(sessionStorage.getItem('dirType'));
        if (this.dirType == 1) {           
            this.tradeAssistanceHeader = "Iowa Directory of Exporters";
        } else {
            this.tradeAssistanceHeader = "International Service Directory";
        }
        this.account = JSON.parse(sessionStorage.getItem('account'));
        if (sessionStorage.getItem('searchType') == "true") {
            console.log("detalNodeData:" + JSON.parse(sessionStorage.getItem('nodeData')));
            this.dirName = JSON.parse(sessionStorage.getItem('nodeData'));
            console.log("dirname:" + this.dirName);
        }
        else {
            console.log("enteredelse:")
            this.dirName = JSON.parse(sessionStorage.getItem('dirName'));//sNode
        }

        console.log("dirtype:" + this.dirType);
        console.log("dirtypesession:" + sessionStorage.getItem('dirType'));

        this.route.params.subscribe(
            params => {
                let id = params['id'];
                this.accountId = id;
                this.GetDetails(id.toString());
            }
        );
    }

    public performFilter(filterBy: any): any[] {
        this.filterByYear = parseInt(filterBy, 10);
       
        if (typeof filterBy === "string") {
            filterBy = filterBy.toLocaleLowerCase();
            //this.GetGroupsByName(filterBy.toString());
            return this.accounts.filter((resourceDirectory: any) => resourceDirectory.name.toLocaleLowerCase().indexOf(filterBy.toString()) !== -1);
        }
        //return this.recipients.filter((recipient: Recipient) => recipient.idAwardYear == filterBy);

    }
       
    onClick(): void {
        //this.idclicked.emit('clicked');
        console.log('The ${this.Id} was clicked');
    }
    //onIdClicked(id: number): void {
    //    //this.message = 'List' + id;  //in html (idclicked)='onIdClicked($event)'
    //}
    onBack(): void {
        this.router.navigate(['/home', JSON.parse(sessionStorage.getItem('dirType'))]);
       // this.router.navigate(['/resourceDirectories'], { queryParams: { sNode: this.sNode } });
        //navigateurl: for complete url path, not used much
    }

    private GetDetails(sNode: String) {
        //this.dataService
        //    .GetAccounts(sNode)
        //    .subscribe(
        //        data => {
        //            this.accounts = data;
        //            this.filteredGroups = data;
        //            console.log("accounts"+data);
        //        },
        //        error => console.log(error),
        //        () => console.log('Get all complete')
        //);
        //this.account = sessionStorage.getItem('account');
        console.log("seaccount:" + this.account);
        this.contactService.Getcontacts(sNode)
            .subscribe(
                data => {
                    this.contacts = data;
                    this.filteredGroups = data;
                    console.log("contacts" + data);
                },
                error => console.log(error),
                () => console.log('Get all contacts complete')
        );
        this.directoryService.GetDirectories(sNode)
            .subscribe(
                data => {
                    this.directories = data;
                    console.log("directories" + data);
                },
                error => console.log(error),
                () => console.log('Get all directories complete')
            );
    }

    //private GetAllResourceDirectories() {
    //    this.dataService
    //        .GetAllAccounts()
    //        .subscribe(
    //            data => {
    //                this.accounts = data;
    //                this.filteredGroups = data;
    //                console.log(data);
    //            },
    //            error => console.log(error),
    //            () => console.log('Get all accounts complete')
    //        );
    //}
    
  
    
}
