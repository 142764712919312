import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';//, Router 

import { ResourceOrganizationService } from './../../core/services/resourceOrganization-data.service';
import { ResourceOrganization } from './../../models/resourceOrganization';



@Component({
    selector: 'app-resourceOrganization-component',
    template: require('./resourceOrganization.component.html'),
    styles:[require('./resourceOrganization.component.scss')]
})

export class ResourceOrganizationComponent implements OnInit {

    public message: string;
   
    public resourceOrganization: ResourceOrganization = new ResourceOrganization();
    public resourceOrganizations: ResourceOrganization[] = [];

    

    constructor(private dataService: ResourceOrganizationService,  
                    private route: ActivatedRoute, //private router: Router
        ) 
    {
        this.message = 'Resource Organization Information ';
    }

    ngOnInit() {
        //let id = +this.route.snapshot.paramMap.get('id');
        this.route.params.subscribe(
            params => {
                let id = +params['id']; // + is a number
                if (id > 0)
                    this.GetResourceOrganization(id.toString());
                //else
                    //this.resourceOrganization.id = id.toString();
            }
        );
        
        // this.getcontacts();
        
    }

   

    //saveGroup(): void {
        
    //    if (this.resourceOrganization.id == 0 )
    //    {
    //        return this.addResourceOrganization();
    //    }
    //    return this.updateResourceOrganization();
    //}

    //public addResourceOrganization() {
    //    this.dataService
    //        .add(this.resourceOrganization)
    //        .subscribe(() => {
    //            this.router.navigate(['/resourceOrganizations']);
    //        }, (error) => {
    //            console.log(error);
    //        });
    //}

    //public updateResourceOrganization() {
    //    this.dataService
    //        .update(this.resourceOrganization.id,this.resourceOrganization)
    //        .subscribe(() => {
    //            //this.dataService.GetRecipient(this.recipient.idRecipient);
    //            //this.getRecipients();
    //            //this.recipient = new Recipient();
    //            this.router.navigate(['/resourceOrganizations']);
    //        }, (error) => {
    //            console.log(error);
    //            });

    //}
     

    //private GetResourceOrganizations() {
    //    this.dataService
    //        .GetResourceOrganizations()
    //        .subscribe(
    //        data => this.resourceOrganizations = data,
    //        error => console.log(error),
    //        () => console.log('Get all complete')
    //        );
    //}
    private GetResourceOrganization(id: string) {
        this.dataService
            .GetResourceOrganization(id)
            .subscribe((resourceOrganization: ResourceOrganization) => {
                this.resourceOrganization = resourceOrganization; console.log("resourceORganizationdata:" + this.resourceOrganization);},
            error => console.log(error),
            () => console.log('Get Resource Organization complete')
        );
        //.subscribe((data: Config) => this.config = { ...data });
    }
    

}
