import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router'; //ActivatedRoute,

import { ResourceDirectoryService } from './../../core/services/resourceDirectory-data.service';
import { ResourceDirectory } from './../../models/resourceDirectory';
//import { PagedResults } from './../../models/PagedResults';
//import { PaginationComponent } from './../../shared/components/pagination/pagination.component';

@Component({
    //selector: 'app-group-component',
    template: require('./resourceDirectory-list.component.html'),
    //styles:[require('./group-list.component.scss')]
})

export class ResourceDirectoryListComponent implements OnInit {
    headers: string[];
    public message: string;
   
    public resourceDirectory: ResourceDirectory = new ResourceDirectory();
    public resourceDirectories: any[] = [];//ResourceDirectory[] = [];
    public filteredGroups: ResourceDirectory[];
    totalRecords: number = 0;
    pageSize: number = 50;
    public dirName = "";
    dirType: number;
    currentUser: 1;//User;
    //page = 1;
    //new paging variables
    //loading = false;
    //total = 0;
    //page = 1;
    //limit = 20;
    //new paging 
    filterByYear: number;
    public _listFilter: any;
    get listFilter(): any {
        return this._listFilter;
    }
    set listFilter(value: any) {
        this._listFilter = value;
        this.filteredGroups = this.listFilter ? this.performFilter(this.listFilter) : this.resourceDirectories;
    }
    
   
    constructor(private dataService: ResourceDirectoryService,  
        private route: ActivatedRoute,
        private router: Router
        ) 
    {
        this.message = 'Resource Directories Information ';
        this.filteredGroups = this.resourceDirectories;
        this.listFilter = '';
    }

    ngOnInit() {
        
        
        this.route.queryParams
            //.filter(params => params.sNode)
            .subscribe(params => {
                console.log("sNode:"+params.sNode); // { order: "popular" }

                //this.order = params.sNode;
                //console.log(this.order); // popular
                if (params.sNode) {
                    this.dirName = params.sNode;
                    sessionStorage.setItem('dirName', JSON.stringify(this.dirName));
                    this.GetResourceDirectories(this.dirName);
                }
                else {
                    this.GetAllResourceDirectories();
                }
            }
            );
        //this.dirType = + sessionStorage.getItem('dirType');
    }

    public performFilter(filterBy: any): ResourceDirectory[] {
        this.filterByYear = parseInt(filterBy, 10);
        //if (typeof this.filterByYear === "number" && !isNaN(this.filterByYear)) //this.filterByYear != NaN &&
        //{
        //    this.getRecipientsByYear(this.filterByYear);
        //    return this.recipients.filter((recipient: Recipient) => recipient.awardYears.intAwardYear == this.filterByYear);
        //    //return this.recipients.filter((recipient: Recipient) => recipient.idAwardYear == this.filterByYear);

        //}
        //public byYear: number; //= Int32.Parse(filterBy);
        //Console.log("{0} --> {1}", filterBy, number);
        if (typeof filterBy === "string") {
            filterBy = filterBy.toLocaleLowerCase();
            //this.GetGroupsByName(filterBy.toString());
            return this.resourceDirectories.filter((resourceDirectory: ResourceDirectory) => resourceDirectory.name.toLocaleLowerCase().indexOf(filterBy.toString()) !== -1);
        }
        //return this.recipients.filter((recipient: Recipient) => recipient.idAwardYear == filterBy);

    }

   
    onClick(): void {
        //this.idclicked.emit('clicked');
        console.log('The ${this.Id} was clicked');
    }
    onIdClicked(id: number): void {
        this.message = 'List' + id;  //in html (idclicked)='onIdClicked($event)'
    }
    onBack(): void {
        this.router.navigate(['/home', JSON.parse(sessionStorage.getItem('dirType'))]);
        //navigateurl: for complete url path, not used much
    }

    private GetResourceDirectories(sNode: string) {
        this.dataService
            .GetResourceDirectories(sNode)
            .subscribe(
                data => {
                    this.resourceDirectories = data;
                    this.filteredGroups = data;
                    console.log(data);
                },
                error => console.log(error),
                () => console.log('Get all complete')
            );
    }

    private GetAllResourceDirectories() {
        this.dataService
            .GetAllResourceDirectories()
            .subscribe(
                data => {
                    this.resourceDirectories = data;
                    this.filteredGroups = data;
                    console.log(data);
                },
                error => console.log(error),
                () => console.log('Get all complete')
            );
    }
    //private GetResourceOrganization(id: number) {
    //    this.dataService
    //        .GetResourceOrganization(id)
    //        .subscribe(
    //            data => {this.resourceOrganization = data},
    //        error => console.log(error),
    //        () => console.log('Get Resource Organization complete')
    //        );
    //}

    //private GetResourceOrganizationsByName(name: string) {
    //    //this.authService.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    //    this.resourceOrganization.Name = name;
    //    this.dataService
    //        .GetResourceOrganizationsByName(this.resourceOrganization)
    //        .subscribe(
    //            data => { this.resourceOrganizations = data; this.filteredGroups = this.resourceOrganizations; },
    //            error => console.log(error),
    //            () => console.log('Get all resource organizations complete')
    //        );
    //    console.log('entered into get resource group organizations');
    //}

  
    
}
