import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
//import { HttpModule } from '@angular/http';

import { DetailComponent } from './components/detail.component';

import { DetailRoutes } from './detail.routes';
import { PipesModule } from './../pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        //HttpModule,
        PipesModule,
        DetailRoutes
    ],

    declarations: [
        DetailComponent
    ] ,

    exports: [
    ]
    , providers: [
    ]
})

export class DetailModule { }
