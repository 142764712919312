import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router'; //ActivatedRoute,
import { SearchService } from '../../core/services/search-data.service';

import { AccountService } from './../../core/services/account-data.service';

import { GenderPipePipe } from './../../pipes/gender-pipe.pipe';
//import { SortByPipe } from './../../pipes/sort.pipe';
import { SortPipe } from './../../pipes/sort.pipe';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
//import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
    //selector: 'app-group-component',
    template: require('./account-list.component.html'),
    //styles:[require('./group-list.component.scss')]
    providers: [

        GenderPipePipe//, MatProgressBarModule
        //, SortByPipe
        , SortPipe
        , ProgressBarModule
    ]
})

export class AccountListComponent implements OnInit {
    headers: string[];
    public message: string;
    public value = 0;
    public running: any; //number

    //color = 'primary';
    //mode = 'determinate';
    //value = 50;
    //bufferValue = 75;
   
    public account: any ; //= new any();
    public accounts: any[] = [];//ResourceDirectory[] = [];
    public filteredGroups: any[];
    totalRecords: number = 0;
    pageSize: number = 50;
    public dirName = "";
    dirType: number;
    searchType: string ;
    currentUser: 1;//User
    progress = 0;
   
    filterByYear: number;
    public _listFilter: any;
    get listFilter(): any {
        return this._listFilter;
    }
    set listFilter(value: any) {
        this._listFilter = value;
        this.filteredGroups = this.listFilter ? this.performFilter(this.listFilter) : this.accounts;
    }
    //phonepipe: PhonePipe;
    persons = [
        {
            id: 1,
            name: 'Hardik Savani',
            gender: 0,
            website: 'itsolutionstuff.com'
        },
        {
            id: 2,
            name: 'Kajal Patel',
            gender: 1,
            website: 'nicesnippets.com'
        },
        {
            id: 3,
            name: 'Harsukh Makawana',
            gender: 0,
            website: 'laracode.com'
        }
    ]

    constructor(private dataService: AccountService, private searchService: SearchService,//phonePipe: PhonePipe,
        private route: ActivatedRoute,
        private router: Router
        ) 
    {
        this.message = 'Resource Directories Information ';
        this.filteredGroups = this.accounts;
        this.listFilter = '';
        console.log("enteredconstructor");
        this.searchType = sessionStorage.getItem("searchType");
        console.log("enteredconstructor1"+this.searchType);
    }

    ngOnInit() {
        //$scope.directoryType = window.localStorage["directoryType"];
        this.searchType = sessionStorage.getItem("searchType");
        //window.localStorage["nodeData"] = $routeParams.sNode;
      
        this.route.queryParams
            //.filter(params => params.sNode)
            .subscribe(params => {
                console.log("sNode:"+params.sNode); // { order: "popular" }

                //this.order = params.sNode;
                //console.log(this.order); // popular
                this.startProgress();
                if (params.sNode) {
                    this.message = "Company List";
                    if (this.searchType == "false") {
                        this.dirName = params.sNode;
                        console.log("dirname"+this.dirName);
                        sessionStorage.setItem('dirName', JSON.stringify(encodeURIComponent(this.dirName)));
                        //this.startProgress();
                        this.GetAccounts(params.sNode);//this.dirName);
                    }
                    else {
                        console.log("searchtypeentered:" + this.searchType);
                        sessionStorage.setItem('nodeData', JSON.stringify(encodeURIComponent(params.sNode)));
                        this.GetSearchResults(params.sNode);
                    }
                }
                else {
                    this.GetAllResourceDirectories();
                }
            }
        );
        //this.dirType = parseInt(sessionStorage.getItem('dirType'));
        //console.log("dirtype:" + this.dirType);
        console.log("dirtypesession:" + sessionStorage.getItem('dirType'));
        //this.phonePipe;
    }


    public performFilter(filterBy: any): any[] {
        this.filterByYear = parseInt(filterBy, 10);
       
        if (typeof filterBy === "string") {
            filterBy = filterBy.toLocaleLowerCase();
            //this.GetGroupsByName(filterBy.toString());
            return this.accounts.filter((resourceDirectory: any) => resourceDirectory.name.toLocaleLowerCase().indexOf(filterBy.toString()) !== -1);
        }
        //return this.recipients.filter((recipient: Recipient) => recipient.idAwardYear == filterBy);

    }
    
    accountClick(account: any) {
        sessionStorage.setItem('account', JSON.stringify(account));
        this.router.navigate(['/detail', account.id]);

    }
    onClick(): void {
        //this.idclicked.emit('clicked');
        console.log('The ${this.Id} was clicked');
    }
    onIdClicked(id: number): void {
        this.message = 'List' + id;  //in html (idclicked)='onIdClicked($event)'
    }
    onBack(): void {
        this.router.navigate(['/home', JSON.parse(sessionStorage.getItem('dirType'))]);
       // this.router.navigate(['/resourceDirectories'], { queryParams: { sNode: this.sNode } });
        //navigateurl: for complete url path, not used much
    }

    public startProgress() {
        this.running = setInterval(() => {
            if (this.value <= 100) {
                this.value++;
            } else {
                this.stopProgress();
            }
        }, 200);
    }

    public stopProgress() {
        if (this.running) {
            clearInterval(this.running);
            this.running = null;
        }
    }

    public resetProgress() {
        this.value = 0;
        this.stopProgress();
    }
    private GetAccounts(sNode: string) {
        this.dataService
            .GetAccounts(sNode)
            .subscribe(                
                data => {
                    this.accounts = data;
                    //this.accounts = this.accounts.pipe(map(arr => arr.sort((a, b) => a > b)));
                    this.filteredGroups = data;
                    //this.startProgress();
                    console.log("accounts"+data);
                },
                error => console.log(error),
                () => {
                    this.stopProgress();
                    console.log('Get all complete')
                }
            );
    }

    private GetSearchResults(sNode: string) {
        sNode = sNode + "|" + JSON.parse(sessionStorage.getItem('dirType'));
        this.searchService
            .GetSearchResults(sNode)
            .subscribe(
                data => {
                    this.accounts = data;
                    this.filteredGroups = data;
                    console.log("searched accounts" + data);
                },
                error => console.log(error),
                () => {
                    this.stopProgress();
                    console.log('Get search all complete')
                }
            );
    }

    private GetAllResourceDirectories() {
        this.dataService
            .GetAllAccounts()
            .subscribe(
                data => {
                    this.accounts = data;
                    this.filteredGroups = data;
                    console.log(data);
                },
                error => console.log(error),
                () => {
                    this.stopProgress();
                    console.log('Get all accounts complete')
                }
            );
    }
    
  
    
}
