import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './components/home.component';

//const routes: Routes = [
//    { path: 'home:id', component: HomeComponent }
//];

const routes: Routes = [

    {
        path: 'home',
        children: [
            
            { path: ':id', component: HomeComponent }
            
        ]
    }


];

export const HomeRoutes = RouterModule.forChild(routes);
