import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
//import { HttpModule } from '@angular/http';

import { ResourceOrganizationComponent } from './components/resourceOrganization.component';
import { ResourceOrganizationListComponent } from './components/resourceOrganization-list.component';
import { ResourceOrganizationDetailComponent } from './components/resourceOrganization-detail.component';

import { ResourceOrganizationService } from './../core/services/resourceOrganization-data.service';
import { ResourceOrganizationRoutes } from './resourceOrganization.routes';
//import { AuthGuard } from './../core/services/auth-guard.service';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        //HttpModule,
        ResourceOrganizationRoutes
    ],

    declarations: [
        ResourceOrganizationComponent,
        ResourceOrganizationListComponent,
        ResourceOrganizationDetailComponent
    ] ,

    exports: [
        ResourceOrganizationComponent
    ]
    , providers: [ResourceOrganizationService]
})

export class ResourceOrganizationModule { }
