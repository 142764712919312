import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GenderPipePipe } from './gender-pipe.pipe';
import { PhonePipe } from './phone-pipe';
import { SortPipe } from './sort.pipe';


@NgModule({
    imports: [
        CommonModule,
    ],

    declarations: [
        GenderPipePipe, PhonePipe, SortPipe
    ],

    exports: [
        GenderPipePipe, PhonePipe, SortPipe
    ]
})

export class PipesModule { }
