import { Component } from '@angular/core';

@Component({
    selector: 'app-custom-footer',
    template: require('./customfooter.component.html'),
    styles: [require('./customfooter.component.scss')]
})

export class CustomFooterComponent {
    currentYear: number = new Date().getFullYear();
}
