import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
//import { HttpModule } from '@angular/http';

//import { ResourceDirectoryComponent } from './components/resourceDirectory.component';
import { AccountListComponent } from './components/account-list.component';
//import { ResourceDirectoryDetailComponent } from './components/resourceDirectory-detail.component';

import { AccountService } from './../core/services/account-data.service';
import { AccountRoutes } from './account.routes';
//import { AuthGuard } from './../core/services/auth-guard.service';
import { PipesModule } from './../pipes/pipes.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        //HttpModule,
        PipesModule,
        BrowserAnimationsModule,
        ProgressBarModule,
        AccountRoutes
    ],

    declarations: [
        AccountListComponent
    ] ,

    exports: [
    ]
    , providers: [AccountService]
})

export class AccountModule { }
