import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
//import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
//import { MatProgressBarModule} from '@angular/material/progress-bar';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routes';
import { CoreModule } from './core/core.module';
import { MainModule } from './main/main.module';
import { HomeModule } from './home/home.module';
import { SharedModule } from './shared/shared.module';
import { ResourceOrganizationModule } from './resourceOrganization/resourceOrganization.module';
import { ResourceDirectoryModule } from './resourceDirectory/resourceDirectory.module';
import { AccountModule } from './account/account.module';
import { DetailModule } from './detail/detail.module';
//import { PhonePipe } from './pipes/phone-pipe';
//import { GenderPipePipe } from './pipes/gender-pipe.pipe';
//import { SortPipe } from './pipes/sort.pipe';

@NgModule({
    imports: [
        BrowserModule,        
        SharedModule,
        CoreModule.forRoot(),
        MainModule,
        HomeModule,
        ResourceOrganizationModule,
        ResourceDirectoryModule,
        AccountModule,
        DetailModule,
        //BrowserAnimationsModule,
        //ProgressBarModule, 
        //DropDownListModule,
        //PhonePipe,
        //MatProgressBarModule,
        //SortPipe,
        AppRoutes,
    ],

    declarations: [
        AppComponent, //SortPipe//, PhonePipe//, GenderPipePipe
    ],
    exports: [//PhonePipe, GenderPipePipe
    ],
    bootstrap: [AppComponent],
})

export class AppModule { }
