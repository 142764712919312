import { RouterModule, Routes } from '@angular/router';

import { DetailComponent } from './components/detail.component';

const routes: Routes = [
    
    {
        path: 'detail',
        children: [
            { path: ':id', component: DetailComponent }
            
        ]
    }
   


];
export const DetailRoutes = RouterModule.forChild(routes);
