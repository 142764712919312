import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'; //ActivatedRoute,

import { ResourceOrganizationService } from './../../core/services/resourceOrganization-data.service';
import { ResourceOrganization } from './../../models/resourceOrganization';


@Component({
    //selector: 'app-group-detail-component',
    template: require('./resourceOrganization-detail.component.html'),
    providers: []
})

export class ResourceOrganizationDetailComponent implements OnInit {

    @Input() Id: number; // this Id is passed from html [Id] ='recipient.IdREcipient' pass data to component
    //@Output() notify: EventEmitter<number> = new EventEmitter<number>(); // from a component
    @Output() idClicked: EventEmitter<number> = new EventEmitter<number>();

    public message: string;
    public showImage: boolean = false;

    public resourceOrganizations: ResourceOrganization[] = [];
    public resourceOrganization: ResourceOrganization = new ResourceOrganization();
    public sNode: string;

   
    
    

    constructor(private dataService: ResourceOrganizationService,
        private route: ActivatedRoute,
        private router: Router) {
        this.message = 'Resource Organization Detail';

    }

    ngOnInit() {
        //let id = +this.route.snapshot.paramMap.get('id');
        //this.sNode = id.toString();
        this.route.params.subscribe(
            params => {
                let id = params['id'];
                this.getResourceOrganization(id.toString());
            }
        );
        //this.route.queryParams
            
        //    .subscribe(params => {
        //        console.log("Id:" + params.orgId); // { order: "popular" }
        //        if (params.orgId) {
        //            this.getResourceOrganization(params.orgId);
        //        }
        //    }
        //    );
        
        // this.getAllThings();
        //this.GetResourceOrganizations(id);
        //this.getResourceOrganization(id.toString());    
        //this.getResourceOrganization();
        //this.listFilter = 2;
    }

    toggleImage(): void {
        this.showImage = !this.showImage;
    }

   
    onClick(): void {
        //this.idclicked.emit('clicked');
        console.log('The ${this.Id} was clicked');
    }
    onIdClicked(id: number): void {
        this.message = 'List' + id;  //in html (idclicked)='onIdClicked($event)'
    }

    onBack(): void {
        //this.router.navigate(['/resourceOrganizations']);
        //this.router.navigate(['/resourceOrganizations'], { queryParams: { orgId: this.resourceOrganization.id } });
        this.sNode = JSON.parse(sessionStorage.getItem('dirName'));
        this.router.navigate(['/resourceDirectories'], { queryParams: { sNode: this.sNode } });
    }


    
    //private GetResourceOrganizations(orgId: string) {
    //    console.log("list:" + orgId);
    //    this.dataService
    //        .GetResourceOrganizations(orgId)
    //        .subscribe(
    //            data => {
    //                this.resourceOrganizations = data;
    //                //this.resourceOrganization = data[0];
    //                //this.filteredGroups = data;
    //                console.log(data);
    //                sessionStorage.setItem('resourceOrganization', JSON.stringify(this.resourceOrganizations));
    //            },
    //            error => console.log(error),
    //            () => console.log('Get all complete')
    //        );
    //}
   
    private getResourceOrganization(id: string) {
        //private getResourceOrganization() {
        console.log("orgdetail"+id);
        //this.resourceOrganizations
        //this.resourceOrganization = JSON.parse(sessionStorage.getItem('resourceOrganization'));
        //console.log("rsor:" + this.resourceOrganization)
        this.dataService
            .GetResourceOrganizationorg(id)
            .subscribe(
                data => {
                    this.resourceOrganization = data;
                    console.log("detailts"+data);
                    console.log("detail:" + this.resourceOrganization);
                    sessionStorage.setItem('resourceOrganization', JSON.stringify(this.resourceOrganization));
                },
                error => console.log(error),
                () => console.log('Get Resource Organization complete')
            );
    }
   
}
