import { RouterModule, Routes } from '@angular/router';

//import { AuthGuard } from './../core/services/auth-guard.service';

//import { ResourceDirectoryComponent } from './components/resourceDirectory.component';
import { AccountListComponent } from './components/account-list.component';
//import { ResourceDirectoryDetailComponent } from './components/resourceDirectory-detail.component';

const routes: Routes = [
    
    {
        path: 'account',
        //canActivate:[AuthGuard],
        children: [
            { path: '', component: AccountListComponent },
            //{ path: 'list/:sNode', component: ResourceDirectoryListComponent },
            
        ],
       // path: 'resourceDirectories/:sNode', component: ResourceDirectoryListComponent 
    },
    


];
//{ path: ':id', component: ResourceDirectoryDetailComponent },
            //{ path: ':id/edit', component: ResourceDirectoryComponent }
export const AccountRoutes = RouterModule.forChild(routes);
