//import { HttpClient, HttpHeaders,HttpErrorResponse,HttpResponse } from '@angular/common/http';
//import { Injectable, PipeTransform } from '@angular/core';
//import { Observable, BehaviorSubject, of, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


import { Configuration } from './../../app.constants';
import { ResourceDirectory } from './../../models/resourceDirectory';


//function compare(v1, v2) {
//    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
//}




@Injectable({ providedIn: 'root' })
export class ResourceDirectoryService {

    private actionUrl: string;
    private headers: HttpHeaders;
    resourceDirectories: ResourceDirectory[];

    
    constructor(private http: HttpClient, configuration: Configuration ) {//,private pipe: DecimalPipe

        this.actionUrl = configuration.Server + 'api/ResourceDirectory';//api/resourceOrganizations/';?sNode=agriculture

        this.headers = new HttpHeaders();
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.headers = this.headers.set('Accept', 'application/json');      
        this.headers.append('Access-Control-Allow-Origin', configuration.Server);
        
    }

    

    GetAllResourceDirectories(): Observable<ResourceDirectory[]> {
        return this.http.get<ResourceDirectory[]>(this.actionUrl , { headers: this.headers });
    }

    //GetResourceDirectories(sNode: String): Observable<ResourceDirectory[]> {
    //    return this.http.get<ResourceDirectory[]>(this.actionUrl+'?sNode='+sNode, { headers: this.headers });
    //}
    GetResourceDirectories(sNode: string): Observable<any[]> {
        return this.http.get<any[]>(this.actionUrl + '?sNode=' + encodeURIComponent(sNode), { headers: this.headers });
    }

    
    GetResourceDirectory(id: number): Observable<ResourceDirectory> {
        if (id == 0) {
            return of(this.initializeResourceDirectory());
        }
        return this.http.get<ResourceDirectory>(this.actionUrl + id, { headers: this.headers })
            .pipe(
                map(resourceDirectory => {
                    console.log(resourceDirectory);
                    return resourceDirectory;
                }),
                catchError(this.handleError)
            );
            ;
    }

    add(resourceDirectoryToAdd: ResourceDirectory): Observable<ResourceDirectory> {
        //const toAdd = JSON.stringify({ name: thingToAdd.name });

       //return this.http.post<Group>(this.actionUrl, toAdd, { headers: this.headers });
        //return this.http.post(this.actionUrl, groupToAdd, { headers: this.headers }).map(res => <Group>res.json());
        return this.http.post<ResourceDirectory>(this.actionUrl, resourceDirectoryToAdd, { headers: this.headers })
            .pipe(
                catchError(this.handleError)
            );
    }

    
    initializeResourceDirectory(): ResourceDirectory {
        // Return an initialized object
        return {
            id: null,     
            name: null,
            resource_Organization__C: null,
            organizations: null,
            organization_Name__C: null,
            category__c:  null            
        };
    }
    private handleError(error: HttpErrorResponse): Observable<any> {
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        //console.error(error.error.message);
        //console.error(error);
        return Observable.throw(error.error.message || 'Server error');

        //if (error.error instanceof ErrorEvent) {
        //    // A client-side or network error occurred. Handle it accordingly.
        //    console.error('An error occurred:', error.error.message);
        //} else {
        //    // The backend returned an unsuccessful response code.
        //    // The response body may contain clues as to what went wrong,
        //    console.error(
        //        `Backend returned code ${error.status}, ` +
        //        `body was: ${error.error}`);
        //}
        //// return an observable with a user-facing error message
        //return throwError(
        //    'Something bad happened; please try again later.');
    }
}
