import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
//import { HttpModule } from '@angular/http';

//import { ResourceDirectoryComponent } from './components/resourceDirectory.component';
import { ResourceDirectoryListComponent } from './components/resourceDirectory-list.component';
//import { ResourceDirectoryDetailComponent } from './components/resourceDirectory-detail.component';

import { ResourceDirectoryService } from './../core/services/resourceDirectory-data.service';
import { ResourceDirectoryRoutes } from './resourceDirectory.routes';
//import { AuthGuard } from './../core/services/auth-guard.service';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        //HttpModule,
        ResourceDirectoryRoutes
    ],

    declarations: [
        //ResourceDirectoryComponent,
        ResourceDirectoryListComponent
        //ResourceDirectoryDetailComponent
    ] ,

    exports: [
        //ResourceDirectoryComponent
    ]
    , providers: [ResourceDirectoryService]
})

export class ResourceDirectoryModule { }
