//import { HttpClient, HttpHeaders,HttpErrorResponse,HttpResponse } from '@angular/common/http';
//import { Injectable, PipeTransform } from '@angular/core';
//import { Observable, BehaviorSubject, of, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


import { Configuration } from './../../app.constants';
import { ResourceOrganization } from './../../models/resourceOrganization';


@Injectable({ providedIn: 'root' })
export class ResourceOrganizationService {

    private actionUrl: string;
    private headers: HttpHeaders;
    resourceOrganizations: ResourceOrganization[];

    
    constructor(private http: HttpClient, configuration: Configuration ) {//,private pipe: DecimalPipe

        this.actionUrl = configuration.Server + 'api/ResourceOrganization';//?sNode="a0kt0000001cqeIAAQ"';//api/resourceOrganizations/';

        this.headers = new HttpHeaders();
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.headers = this.headers.set('Accept', 'application/json');      
        this.headers.append('Access-Control-Allow-Origin', configuration.Server);

        
    }


    GetAllResourceOrganizations(): Observable<ResourceOrganization[]> {
        return this.http.get<ResourceOrganization[]>(this.actionUrl , { headers: this.headers });
    }

    GetResourceOrganizations(sNode: string): Observable<ResourceOrganization[]> {
        return this.http.get<ResourceOrganization[]>(this.actionUrl+'?sNode=' + sNode, { headers: this.headers });
    }

    
    GetResourceOrganization(id: string): Observable<ResourceOrganization> {
        console.log("id:" + id);
        if(id == "") {
            return of(this.initializeResourceOrganization());
        }
        return this.http.get<ResourceOrganization>(this.actionUrl+"?id=" + id, { headers: this.headers })
            .pipe(
                map(resourceOrganization => {
                    console.log(resourceOrganization);
                    return resourceOrganization;
                }),                catchError(this.handleError)

            );
        ;
    }
    GetResourceOrganizationorg(sNode: string): Observable<ResourceOrganization> {
        if (sNode == "") {
            return of(this.initializeResourceOrganization());
        }
        return this.http.get<ResourceOrganization>(this.actionUrl +'?sNode='+ sNode, { headers: this.headers })
            .pipe(
                map(resourceOrganization => {
                    console.log(resourceOrganization);
                    return resourceOrganization;
                }),
                catchError(this.handleError)
            );
            ;
    }

    add(resourceOrganizationToAdd: ResourceOrganization): Observable<ResourceOrganization> {
        return this.http.post<ResourceOrganization>(this.actionUrl, resourceOrganizationToAdd, { headers: this.headers })
            .pipe(
                catchError(this.handleError)
            );
    }

    initializeResourceOrganization(): ResourceOrganization {
        // Return an initialized object
        return {
            id: null,  
            name: null,  
            description__C: null,
            organization_Name__C: null,
            category_Listing__C: null,                                
            website__C: null
        };
    }
    private handleError(error: HttpErrorResponse): Observable<any> {
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        //console.error(error.error.message);
        //console.error(error);
        return Observable.throw(error.error.message || 'Server error');

        //if (error.error instanceof ErrorEvent) {
        //    // A client-side or network error occurred. Handle it accordingly.
        //    console.error('An error occurred:', error.error.message);
        //} else {
        //    // The backend returned an unsuccessful response code.
        //    // The response body may contain clues as to what went wrong,
        //    console.error(
        //        `Backend returned code ${error.status}, ` +
        //        `body was: ${error.error}`);
        //}
        //// return an observable with a user-facing error message
        //return throwError(
        //    'Something bad happened; please try again later.');
    }
}
