/// <reference path="resourceorganization.module.ts" />
import { RouterModule, Routes } from '@angular/router';

//import { AuthGuard } from './../core/services/auth-guard.service';

import { ResourceOrganizationComponent } from './components/resourceOrganization.component';
import { ResourceOrganizationListComponent } from './components/resourceOrganization-list.component';
import { ResourceOrganizationDetailComponent } from './components/resourceOrganization-detail.component';

const routes: Routes = [
    
    {
        path: 'resourceOrganizations',
        //canActivate:[AuthGuard], { path: '', component: ResourceOrganizationListComponent },{ path: '', component: ResourceOrganizationDetailComponent },
        children: [
            { path: '', component: ResourceOrganizationListComponent },
            { path: ':id', component: ResourceOrganizationDetailComponent },
            { path: ':id/edit', component: ResourceOrganizationComponent }
        ]
    }
    //,{ path: 'resourceOrganizations/:orgId', component: ResourceOrganizationDetailComponent }


];

export const ResourceOrganizationRoutes = RouterModule.forChild(routes);
